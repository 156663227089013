/**
 * Configure your Gatsby site with this file.
 *
 * See: https://www.gatsbyjs.com/docs/gatsby-config/
 */

module.exports = {
  siteMetadata: {
    title: `Iconity`,
    description: `Iconity`,
    siteUrl: `https://www.iconity.ai`,
    author: `Iconity`,
  },
  flags: {
    DEV_SSR: false,
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    "gatsby-plugin-sass",
    {
      resolve: `gatsby-plugin-force-trailing-slashes`,
      options: {
        excludedPaths: [`/404.html`],
      },
    },
    {
      resolve: "gatsby-plugin-react-svg",
      options: {
        rule: {
          include: /icons/,
        },
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Iconity`,
        short_name: `Iconity`,
        start_url: `/`,
        background_color: `#e9ebed`,
        theme_color: `#363d4a`,
        display: `browser`,
        icon: `src/assets/images/favicon.svg`,
      },
    },
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: "ouIxZavi9Yln3GDR0VnFEgtt",
        homeSlug: "home",
        version: process.env.NODE_ENV === "production" ? "published" : "draft",
        resolveRelations: [
          "testimonial",
          "testimonials",
          "logosRight",
          "logosLeft",
          "expertiseGuarantorTestimonial",
          "clientTestimonial",
          "recruiterCard",
          "expertiseGuarantor",
          "personalIntro",
          "headPersonalIntro",
          "jobApplicationForm",
          "reusableCtaBanner",
          "authors",
          "presenter",
          "presenters",
          "reusableForm",
          "blogCards",
          "webinarCards",
          "whitepaperCards",
          "onDemandCards",
          "form",
        ],
      },
    },
    {
      resolve: "gatsby-plugin-google-tagmanager",
      options: {
        id: "GTM-54HHQD4",
      },
    },
    {
      resolve: "gatsby-plugin-cookiebot",
      options: {
        cookiebotId: "116b64fe-b94b-4a78-9485-b0a9d35035cd", // Required. Site's Cookiebot ID.
        manualMode: false, // Optional. Turns on Cookiebot's manual mode. Defaults to false.
        blockGtm: true, //  Optional. Skip blocking of GTM. Defaults to true if manualMode is set to true.
      },
    },
    {
      resolve: "gatsby-plugin-sitemap",
      options: {
        exclude: ["/editor"],
        query: `
          {
            site {
              siteMetadata {
                siteUrl
              }
            }
            allSitePage {
              nodes {
                context {
                  story {
                    content
                  }
                }
                path
              }
            }
          }
        `,
        serialize: ({ site, allSitePage }) => {
          const siteMapEntries = []

          // Add HP
          siteMapEntries.push({
            url: `${site.siteMetadata.siteUrl}`,
            changefreq: "daily",
            priority: 0.7,
          })

          // PDF Pages
          ;[
            { url: "/cookies-policy.pdf", changefreq: "monthly" },
            { url: "/consent-candidates.pdf", changefreq: "monthly" },
            { url: "/consent-clients.pdf", changefreq: "monthly" },
            { url: "/privacy-policy.pdf", changefreq: "monthly" },
            { url: "/gdpr.pdf", changefreq: "monthly" },
          ].forEach(pdfPage => {
            siteMapEntries.push({
              url: `${site.siteMetadata.siteUrl}${pdfPage.url}`,
              changefreq: pdfPage.changefreq,
              priority: 0.5,
            })
          })

          // Storyblok Pages
          allSitePage.nodes.forEach(node => {
            const content =
              node.context && node.context.story && node.context.story.content
                ? JSON.parse(node.context.story.content)
                : undefined

            const isNoIndex =
              content && content.seoMeta && content.seoMeta.noIndex
            const isNotPage = content && !content.seoMeta
            // in case there is no seoMeta we do not build it -> it is only a component then
            const changefreq =
              content && content.seoMeta && content.seoMeta.changefreq
                ? content.seoMeta.changefreq
                : "daily"
            const priority =
              content && content.seoMeta && content.seoMeta.priority
                ? content.seoMeta.priority
                : 0.7

            if (!isNotPage && !isNoIndex && node.path !== "/") {
              siteMapEntries.push({
                url: `${site.siteMetadata.siteUrl}${node.path}`,
                changefreq: changefreq,
                priority: Number(priority),
              })
            }
          })

          return siteMapEntries
        },
      },
    },
  ],
}
